<template>

            <div class="table-content">
                <div class="table-wrapper">
                    <table class="table table-bordered-dark table-striped-dark">
                        <thead>
                        <tr class="text-center">
                            <th>Parkovací pozice</th>
                            <th>Celkem</th>
                            <th>Nové</th>
                            <th>Použité</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(row, key) in skladoveZasoby">
                            <tr v-if="row.kod_pozice != ''">
                                <td>{{ row.zkratka_regalu }}  -  {{ row.kod_pozice }}</td>
                                <td class="text-center">{{ row.pocet_celkem | formatNumber}} {{ mj }}</td>
                                <td class="text-center">{{ row.pocet_mj_nove | formatNumber }} {{ mj }}</td>
                                <td class="text-center text-success">{{ row.pocet_mj_pouzite | formatNumber }} {{ mj }}</td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
</template>

<script>
export default {
    name: "SkladoveZasobyDetail",
    data() {
        return {
            skladoveZasoby: {},
            mj: ''
        }
    },
    props: {
        data: {},
        mernaJednotka: ''
    },
    created() {
        let skladoveZasoby = this.data;
        $.each(skladoveZasoby, function (key, value) {
            value.pocet_celkem = value.pocet_mj_nove + value.pocet_mj_pouzite;
        });
        this.skladoveZasoby = skladoveZasoby;
        this.mj = this.mernaJednotka;
    },
    filters: {
        maxLength(value, max = 35)
        {
            return Helper.maxLength(value, max);
        },
        formatNumber(value) {
            return Helper.formatNumber(value);
        },
        decimalNumber(value) {
            return Helper.decimalNumber(value);
        },
    }
}
</script>

<style lang="scss" scoped>
    .skladove-zasoby-detail {
        .table-wrapper {

        }
    }
</style>