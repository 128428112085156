<template>
    <div :key="'detail-polozky-' + keyDetailPolozky" v-cloak>
        <div class="button-tisk-stitku" v-if="idPolNdPolozky > 0">
            <the-button
                @button="vytiskniStitek($event)"
                circle="false"
                icon="print"
                color="light"
                size="sm"
                value=""
            />
            <the-button
                v-if="showDetail == true"
                @button="zobrazitDetail($event)"
                circle="false"
                icon="search"
                color="light"
                size="sm"
                value=""
            />
        </div>
        <the-carousel :nazev="polozka.nazev_polozky" :fotoArray="polozka.foto_array"></the-carousel>
        <div class="row detail-polozky">
            <div class="col-12 text-danger text-bold mt-2 mb-2 text-center" v-if="idAktualnihoSkladu != polozka.id_aktualniho_skladu && idPolNdPolozky > 0">
               Tato položka se nachází v jiném skladě! <br> <br> Zkontrolujte příjemky a výdejky této položky<br>  nebo změňte aktivní sklad
            </div>


            <div class="col-4 text-right">Skladové číslo</div>
            <div class="col-8 text-left text-bold">{{ polozka.skladove_cislo }}</div>
            <div :class="'col-4 text-right'" v-if="showCisloPolozky == true">Číslo položky</div>
            <div :class="'col-8 text-left text-bold ' + polozka.barva_polozky" v-if="showCisloPolozky == true">{{ polozka.cislo_polozky }}</div>
            <div class="col-4 text-right">Název</div>
            <div class="col-8 text-left text-bold">{{ polozka.nazev_polozky }}</div>
            <div class="col-4 text-right">Typ</div>
            <div class="col-8 text-left">{{ polozka.typ_polozkovani_vypis }}</div>
            <div :class="'col-4 text-right'" v-if="showCisloPolozky == true">Pozice</div>
            <div class="col-8 text-left text-bold" v-if="showCisloPolozky == true">
                <router-link
                    :to="'/detail/parkovaci-pozice/' + polozka.id_pozice_regalu"
                    v-if="polozka.nazev_skladu != null && polozka.nazev_skladu != ''"
                >
                    {{ polozka.nazev_skladu }} - {{ polozka.zkratka_regalu }} - {{ polozka.kod_pozice }}
                </router-link>
                <span class="text-danger" v-if="polozka.nazev_skladu == null || polozka.nazev_skladu == ''">
                    Nezaparkováno
                </span>
            </div>
            <div :class="'col-4 text-right'" v-if="polozka.nazev_skladu == null && idPolNdPolozky > 0">Sklad</div>
            <div class="col-8 text-left text-bold" v-if="polozka.nazev_skladu == null">{{ polozka.nazev_aktualniho_skladu }}</div>
        </div>
    </div>
</template>

<script>
import axiosApiPostV2 from "@/router/axiosApiMethod";
import TheCarousel from "@/components/TheCarousel";
import TheButton from "@/components/TheButton";
import router from "@/router";
import {busReload} from "../../main";
import store from "../../store";

export default {
    components: {
        TheCarousel, TheButton
    },
    name: "DetailPolozky",
    data() {
        return {
            polozka: {},
            showDetail: true,
            showCisloPolozky: false,
            keyDetailPolozky: 0,
            idAktualnihoSkladu: 0,
            adminDir: window.adminDir
        }
    },
    props: {
        idPolozky: 0,
        idPolNdPolozky: 0,
        hideDetail: false,
        showLoading: false
    },
    created() {

        this.idAktualnihoSkladu = store.state.sklad.id_skladu;

        if (this.showLoading == true)
        {
            //this.$root.$emit('showLoader', {show: true});
        }
        if (this.idPolozky == 0)
        {
            this.idPolozky == this.$route.params.id_polozky;
        }
        if (this.idPolNdPolozky == 0 && this.$route.params.id_pol_nd_polozky > 0)
        {
            this.idPolNdPolozky = this.$route.params.id_pol_nd_polozky;
        }

        if (this.idPolNdPolozky > 0)
        {
            this.showCisloPolozky = true;
        }
        if (this.hideDetail == true)
        {
            this.showDetail = false;
        }
        let params = {
            id_polozky: this.idPolozky,
            id_pol_nd_polozky: this.idPolNdPolozky,
            pocet_skladem: true
        };
        axiosApiPostV2('detail-polozky', params, 'detail').then( detailPolozky => {
            let polozka = detailPolozky;
            //console.log(polozka);
            this.$root.mernaJednotkaND = detailPolozky.merna_jednotka;
            polozka.pouzita_polozka_vypis = 'Ne';
            if (polozka.pouzite == 1)
            {
                polozka.pouzita_polozka_vypis = 'Ano';
                polozka.barva_polozky = 'text-success';
            }

            let fotoArrayEdit = [];
            let pocetFoto = 0;

            if (polozka.primarni_foto != '')
            {
                fotoArrayEdit.push(polozka.primarni_foto);
                pocetFoto++;
            }

            if (polozka.neprimarni_foto != '')
            {
                let fotoArray = polozka.neprimarni_foto.split(',');
                $.each(fotoArray, function (key, neprimarniFoto)
                {
                    if (neprimarniFoto != '')
                    {
                        fotoArrayEdit.push(neprimarniFoto);
                        pocetFoto++;
                    }
                });
            }

            if (pocetFoto == 0)
            {
                let foto = '../../../assets/not_found.png';
                fotoArrayEdit.push(foto);
            }
            polozka.foto_array = fotoArrayEdit;
            this.polozka = polozka;
            if (this.showLoading == true)
            {
                //this.$root.$emit('showLoader', {show: false});
            }
        });
        busReload.$on('reload', args => {
            let params = {
                id_polozky: this.idPolozky,
                id_pol_nd_polozky: this.idPolNdPolozky,
                pocet_skladem: true
            };
            axiosApiPostV2('detail-polozky', params, 'detail').then( detailPolozky => {
                this.polozka.nazev_skladu = detailPolozky.nazev_skladu;
                this.polozka.zkratka_regalu = detailPolozky.zkratka_regalu;
                this.polozka.kod_pozice = detailPolozky.kod_pozice;
                this.polozka.id_pozice_regalu = detailPolozky.id_pozice_regalu;
            });
            this.keyDetailPolozky++;
        });
    },
    methods: {
        vytiskniStitek(event)
        {

            let swalAl = swal({
                title: "Chcete vytisknout štítek? ",
                text: 'Po potvrzení se vytiskne štítek na Vaši zvolené tiskárně v adminu.',
                icon: "warning",
                buttons: ["Storno", "Ano"],
            }).then((success) => {
                if (success)
                {
                    this.$root.$emit('showLoader', {show: true});
                    let params = {
                        id_pol_nd_polozky: this.$route.params.id_pol_nd_polozky,
                        id_polozky: this.$route.params.id_polozky
                    };
                    axiosApiPostV2('tisk-stitku-pol-nd', params, 'result').then( result => {
                        //console.log(result);
                        if (result == 'Ok')
                        {
                            this.$root.$emit('alertMessage', {text: 'Štítek byl odeslán na tiskárnu', color: 'success', autoHide: 3, strong: ''})
                        }
                        else
                        {
                            this.$root.$emit('alertMessage', {text: result, color: 'danger', autoHide: 3, strong: 'Štítek se nepodařilo vytisknout!'})
                        }
                        this.$root.$emit('showLoader', {show: false});
                    });
                }
                });
        },
        zobrazitDetail()
        {
            this.$root.$emit('route', {page: '/detail/pol-nd/' + this.idPolozky + '/' + this.idPolNdPolozky});
        },

    },

}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";
.detail-polozky {
    border-bottom: 1px solid $color-grey;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    line-height: 16px;
}
.col-4, .col-8 {
    margin-top: 0.5em;
    font-size: 0.9em;
}

.button-tisk-stitku {
    position: absolute;
    z-index: 50;
    left: 0;
    a {
        display: list-item;
        margin-top: 10px;
    }
}

a {
    color: #4e4f4d;
    text-decoration: underline;
}

</style>