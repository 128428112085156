<template>
    <div>
        <div class="row">
            <div class="col-12 text-center">
                <img :src="fotoCesta" :key="fotoKey" v-if="fotoCesta != ''">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NahledFoto",
    data() {
        return {
            fotoCesta: '',
            fotoKey: 0
        }
    },
    created() {
        let decode = atob(this.$route.params.cesta_hash);
        decode = adminDir + decode;
        this.fotoCesta = decode;
        this.fotoKey++;

    }
}
</script>

<style lang="scss" scoped>
    img {
        width: 100%;
        height: 100%;
    }
</style>