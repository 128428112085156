<template>
    <div v-cloak>
        <div v-if="loadDetail == 1" :key="loadDetailKey">
            <detail-polozky :id-polozky="idPolozky" :id-pol-nd-polozky="idPolNdPolozky" :hide-detail="true" :show-loading="true"></detail-polozky>
        </div>

        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Zaparkované položky
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Parkovací pozice</th>
                                <th>Množství nové</th>
                                <th>Množství použité</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(regal, id) in zaparkovanePolozky">
                                {{ regal }}
                                <td class="text-center">{{ regal.zkratka_regalu }} - {{ regal.kod_pozice }}</td>
                                <td class="text-center">
                                    {{ regal.pocet_mj_nove }} /
                                    <span class="text-success">{{ regal.pocet_mj_pouzite }}</span>
                                </td>
                                <td class="text-center">
                                    <span class="text-danger" v-if="regal.je_mozno_zaparkovat == false">NE</span>
                                    <span class="text-success" v-if="regal.je_mozno_zaparkovat == true">ANO</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Historie parkování
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Datum</th>
                                <th>Počet MJ</th>
                                <th>Volné místo</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(historie, id) in historieParkovani">
                                <td class="text-center"> {{ historie.datum_format }} </td>
                                <td class="text-center"> {{ historie.nazev_regalu_nova }} - {{ historie.kod_pozice_nova }} </td>
                                <td> {{ historie.prijmeni }} {{ historie.jmeno }} </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Dodavatelé <small>({{ Object.keys(modelovaSkladovaCislaSpolecnosti).length }})</small>
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Společnost</th>
                                <th>Skladové č.</th>
                                <th>Modelové č.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(spolecnost, id) in modelovaSkladovaCislaSpolecnosti">
                                <td><span :title="spolecnost.nazev_spolecnosti">{{ spolecnost.nazev_spolecnosti | maxLength(20) }}</span></td>
                                <td class="">{{ spolecnost.skladove_cislo }}</td>
                                <td class="">{{ spolecnost.modelove_cislo }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <the-card class="mt-2 mb-2" :default-open="true">
            <template v-slot:header>
                Parametry
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Parametr</th>
                                <th>Hodnota</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(parametr, id) in parametry">
                                <td>{{ parametr.nazev_parametru }}</td>
                                <td>{{ parametr.hodnota | formatNumber}} <span v-if="parametr.merna_jednotka != 'Žádná měrná jednotka'">{{ parametr.merna_jednotka }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <the-card class="mt-2 mb-5" :default-open="true">
            <template v-slot:header>
                Skladové zásoby
            </template>
            <template v-slot:body>
                <h5 class="">Celkem skladem: <b>{{ mnozstviZustatekCelkem | formatNumber }} {{ mernaJednotka }}</b></h5>
                <template v-for="(zasoby, id) in skladoveZasoby">

                    <div class="top-20">
                        <div class="table-content">
                            <div class="table-wrapper">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                    <tr class="text-center text-small">
                                        <th>Spol.</th>
                                        <th>Sklad</th>
                                        <th>Celkem</th>
                                        <th>Nové</th>
                                        <th>Použité</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-small">
                                    <template v-for="(row, key) in zasoby">
                                        <tr @click="showHideDetail(row.id_skladu)">
                                            <td>{{ row.nase_spolecnost }}</td>
                                            <td>{{ row.zkratka_pobocky }} - {{ row.nazev_skladu | maxLength(27, false)}}</td>
                                            <td class="text-center">{{ row.mnozstvi_celkem | formatNumber }} {{ mernaJednotka }}</td>
                                            <td class="text-center">{{ row.mnozstvi_zustatek_nove | formatNumber }} {{ mernaJednotka }}</td>
                                            <td class="text-success text-center">{{ row.mnozstvi_zustatek_pouzite | formatNumber }} {{ mernaJednotka }}</td>
                                        </tr>
                                        <template v-if="row.mnozstvi_celkem > -1">
                                            <tr :id="'detail-' + row.id_skladu" class="skladove-zasoby-detail hide" @click="showHideDetail(row.id_skladu)">
                                                <td colspan="5">
                                                    <skladove-zasoby-detail :key="keyWan[row.id_skladu]" :data="skladoveZasobySkladu" :merna-jednotka="mernaJednotka"/>
                                                </td>
                                            </tr>
                                            <tr class="skryty-radek hide"></tr>
                                        </template>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </the-card>

    </div>
</template>

<script>
import DetailPolozky from "@/components/polozka/DetailPolozky";
import axiosApiPostV2 from "@/router/axiosApiMethod";
import SkladoveZasobyDetail from "@/views/content/detail/SkladoveZasobyDetail";
import TheCard from "../../../components/TheCard";
export default {
    components: {TheCard, SkladoveZasobyDetail, DetailPolozky},
    name: "PolND",
    data() {
        return {
            idPolozky: this.$route.params.id_polozky,
            parametry: {},
            skladoveZasoby: {},
            mernaJednotka: '',
            mnozstviZustatekCelkem: '',
            zobrazitRadek: [],
            skladoveZasobySkladu: {},
            keyWan: {},
            idPolNdPolozky: 0,
            loadDetail: 0,
            loadDetailKey: 1,
            zaparkovanePolozky: {},
            historieParkovani: {},
            modelovaSkladovaCislaSpolecnosti: {}
        }
    },
    created() {
        this.$root.$emit('showLoader', {show: true});
        if (this.$route.params.id_pol_nd_polozky !== null)
        {
            this.idPolNdPolozky = this.$route.params.id_pol_nd_polozky;
        }
        axiosApiPostV2('detail-pol-nd-data', {id_polozky: this.idPolozky, id_pol_nd_polozky: this.idPolNdPolozky}, 'data').then( detailPolNdData => {
            this.zaparkovanePolozky = detailPolNdData.zaparkovane_polozky;
            this.historieParkovani = detailPolNdData.historie_parkovani;
            this.modelovaSkladovaCislaSpolecnosti = detailPolNdData.modelova_skladova_cisla;
            this.parametry = detailPolNdData.parametry;
            let skladoveZasoby = detailPolNdData.skladove_zasoby;

            //this.skladoveZasoby = skladoveZasoby['sklady'];
            let keyWan = [];
            let skladoveZasobySort = {};
            $.each(skladoveZasoby['sklady'], function (key, array) {
                $.each(array, function (keySklad, value) {
                    value.poradi = parseInt(value.poradi);

                    if (value.mnozstvi_celkem < 0)
                    {
                        value.mnozstvi_celkem = 0;
                    }

                    if (value.mnozstvi_zustatek_nove < 0)
                    {
                        value.mnozstvi_zustatek_nove = 0;
                    }

                    if (value.mnozstvi_zustatek_pouzite < 0)
                    {
                        value.mnozstvi_zustatek_pouzite = 0;
                    }

                    if (skladoveZasobySort[key] === undefined)
                    {
                        skladoveZasobySort[key] = {}
                    };
                    if (skladoveZasobySort[key][value.poradi] === undefined)
                    {
                        skladoveZasobySort[key][value.poradi] = {}
                    };
                    skladoveZasobySort[key][value.poradi] = value;
                    let idSkladu = value.id_skladu;
                    keyWan[idSkladu] = 0;
                    //value.nazev_skladu = value.nazev_skladu.substring(0, 27);
                });
            });
            this.skladoveZasoby = skladoveZasobySort;
            this.keyWan = keyWan;
            this.mernaJednotka = this.$root.mernaJednotkaND;
            this.mnozstviZustatekCelkem = skladoveZasoby['celkem']['mnozstvi_zustatek_celkem'];
            this.loadDetail = 1;
            this.loadDetailKey++;
            this.$root.$emit('showLoader', {show: false});
        });
    },
    methods: {
        showHideDetail(idSkladu)
        {
            let skryty = $("#detail-" + idSkladu).hasClass('hide');
            if (skryty == true)
            {
                $( "#detail-" + idSkladu ).removeClass( "hide" );
            }
            else
            {
                $( "#detail-" + idSkladu ).addClass( "hide" );
            }
            this.$root.$emit('showLoader', {show: true});
            axiosApiPostV2('skladove-zasoby-parkovaci-pozice', {id_polozky: this.idPolozky, id_skladu: idSkladu}, 'data').then( skladoveZasoby => {
                this.skladoveZasobySkladu = skladoveZasoby;
                this.keyWan[idSkladu]++;
                this.$root.$emit('showLoader', {show: false});
            });
        }
    },
    filters: {
        maxLength(value, max = 35, addPoints)
        {
            return Helper.maxLength(value, max, addPoints);
        },
        formatNumber(value) {
            return Helper.formatNumber(value);
        },
        decimalNumber(value) {
            return Helper.decimalNumber(value);
        },
    }
}
</script>

<style lang="scss" scoped>
h4 {
    font-size: 1.4em;
    padding-left: 8px;
}
h5 {
    font-size: 1.2em;
    padding-left: 8px;
}
.skladove-zasoby-detail {
    //background: #fffeee;
}
</style>